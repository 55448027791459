<template>
  <section class="no-content-wrap">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <h6 class="no-members--title">{{ config.title }}</h6>

      <slot name="menu"></slot>

      <CDropdown
        v-if="!this.$slots.menu"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler class="justify-content-end">
          <CButton
            class="create-or-load-action-btn"
            color="dark"
            variant="outline"
          >
            Actions <i class="fa fa-caret-down"></i
          ></CButton>
        </template>
        <CDropdownItem
          class="p-0"
          v-if="config.uploadTitle && !this.empty(modalConfig)"
        >
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="toggleModal((modal = !modal))"
          >
            {{ config.uploadTitle }}
          </CButton>
        </CDropdownItem>
        <div v-if="typeof config.createTitle === 'object'">
          <CDropdownItem class="p-0" v-for="(title, key) in config.createTitle" :key="key">
            <CButton
              pill
              color="dark"
              class="action-custom-button"
              variant="ghost"
              @click="create(key)"
            >
              {{title}}
            </CButton>
          </CDropdownItem>
        </div>
        <CDropdownItem class="p-0" v-else>
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="create"
          >
            {{ config.createTitle }}
          </CButton>
        </CDropdownItem>
      </CDropdown>

      <CsvImportModal
        v-on:load-csv="loadCsv"
        :config="modalConfig"
        :modalShow="modal"
        v-on:toggle-modal="closeModal"
      />
    </div>
  </section>
</template>

<script>
import CsvImportModal from '@/components/CsvImportModal';
import { isEmpty } from 'lodash';

export default {
  components: {
    CsvImportModal,
  },
  props: {
    modalConfig: Object,
    config: {
      type: Object,
      default() {
        return {
          title: String,
          uploadTitle: String,
          createTitle: String,
          path: String,
        };
      },
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    loadCsv(val) {
      console.log('CSV PARSED', val);
    },
    create(key) {
      if (key && typeof key === 'string') {
        this.$router.push({
          name: this.config.path[key],
        });
        return;
      } else {
        if (this.config.path) {
          this.$router.push({
            name: this.config.path,
          });
        }
      }
      this.$emit('click');
    },
    toggleModal(val) {
      this.modal = val;
    },
    closeModal() {
      this.modal = false;
    },
    empty(val) {
      return isEmpty(val);
    },
  },
};
</script>

<style lang="scss">
.create-or-load-action-btn {
  border-radius: 12px;
}
.no-content-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100vh - 271px);
  background: var(--zq-sub-bg);

  .action-custom-button {
    width: 100%;
  }

  h6 {
    text-align: center;
    margin-bottom: 1%;
  }
}
</style>
