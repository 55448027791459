<template>
  <div>
    <PreviewSpiner
      v-if="!isReady"
      :texts="['Validating...', 'Preparing...', 'Redirecting...']"
    />
    <div v-else>
      <div v-if="hasAtLeastOne" class="content">
        <CRow>
          <CCol col="12">
            <TableHeader
              :originalFields="originalFields"
              :searchableAllFields="searchableAllFields"
              :fields="fields"
              :statuses="statuses"
              :defaultStatus="defaultStatus"
              :isVisible="selectedRecords.length"
              :disabled="!ready"
              :isCreateButtonVisible="isCreateButtonVisible"
              :isCogFieldsButtonVisible="isCogFieldsButtonVisible"
              :isFilterButtonVisible="isFilterButtonVisible"
              :isLogEvents="isLogEvents"
              @updateFields="updateFields"
              @createItem="createNewRecord"
              @toggleFilter="showColumnFilter"
              @checkStatus="checkStatus"
              @searchData="searchData"
              @clearFilter="clearFilter"
              @deleteData="deleteData"
              @reload="reload"
              @cancelAction="cancelAction"
              @uploadFromCSV="uploadFromCSV"
            />
          </CCol>
        </CRow>
        <CRow class="mt-1">
          <CCol col="12">
            <CCard class="zq-card-table">
              <CCardBody>
                <CDataTable
                  id="dataTableId"
                  ref="dataTableRef"
                  class="zq--table"
                  :class="{ 'is-show-filter': columnFilter }"
                  :items="tableData"
                  :fields="tableFields"
                  :searchableAllFields="searchableAllFields"
                  :column-filter="columnFilter"
                  :items-per-page="itemsPerPage"
                  :sorter="{ external: true }"
                  :responsive="true"
                  :border="isDefaultTheme"
                  :hover="isDefaultTheme"
                  :striped="isDefaultTheme"
                  :loading="loading"
                  @pagination-change="itemsPerPageSelect"
                  @page-change="paginationChange"
                  @update:sorter-value="handleSortBy"
                  @update:column-filter-value="handleColumnFilter"
                  :sorter-value.sync="sorterValue"
                >
                  <template #loading>
                    <TableLoader />
                  </template>
                  <template #id="{ item }">
                    <td class="nth-id">
                      <div class="copy-ceil">
                        <div v-if="!isLogEvents" class="link" @click="showPreview(item)">
                          {{ item.id }}
                        </div>
                        <div v-else>
                          {{ item.id }}
                        </div>
                        <i
                          class="fa fa-copy zq-card-row--copy-icon"
                          @click="handleCopy(item.id)"
                        />
                      </div>
                    </td>
                  </template>
                  <template #spaceName="{ item }">
                    <td>{{ getSpaceName(item.spaceName) }}</td>
                  </template>
                  <template #email="{ item }">
                    <td class="nth-id">
                      <div class="link" @click="showPreview(item)">
                        {{ item.email }}
                      </div>
                    </td>
                  </template>
                  <template #key="" v-if="model === 'apiKeys'">
                    <td>*************************</td>
                  </template>
                  <template #created="{ item }">
                    <td class="nth-created"> {{ dateFormate(item.created) }}</td>
                  </template>
                  <template #select-header class="text-center">
                    <div
                      class="position-relative zq--table-select-all"
                      v-theme-select-all
                    >
                      <ClCheckbox
                        :checkedProp="toggleFlag"
                        icon="fa-angle-down"
                        @handler="selectAll"
                      />
                    </div>
                  </template>
                  <template #system="{ item }">
                    <td>
                      <CSwitch
                        color="success"
                        class="mt-1"
                        shape="pill"
                        :checked="item.constraints.includes('system')"
                        labelOn="Yes"
                        labelOff="No"
                        :disabled="true"
                      />
                    </td>
                  </template>
                  <template #metadata="{item}">
                    <td>{{ getMetaData(item.metadata) }}</td>
                  </template>
                  <template #customFields="{item}">
                    <td>{{ getCustomFields(item.customFields) }}</td>
                  </template>
                  <template #entity="{ item }">
                    <td>{{ item.entityTypes.join(', ') }}</td>
                  </template>
                  <template #actionTypeAdjustmentFactors="{ item }">
                    <td v-if="item.actionTypeAdjustmentFactors" class="nth-action-type">
                      <div v-for="(item, index) in getActionsAdjustmentFactorsFields(item.actionTypeAdjustmentFactors)" :key="index">
                        <div class="d-flex text-left">
                          <div>
                            {{ item }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td v-else>-</td>
                  </template>
                  <template #description="{ item }">
                    <td v-if="item.description">
                      {{ truncateAndClearText(item.description) }}
                    </td>
                    <td v-else>-</td>
                  </template>
                  <template #termsAndConditions="{ item }">
                    <td v-if="item.termsAndConditions">
                      {{ truncateAndClearText(item.termsAndConditions) }}
                    </td>
                    <td v-else>-</td>
                  </template>
                  <template #stackTrace="{ item }">
                    <td>
                      <div v-if="item.stackTrace.length > 1" style="cursor: pointer" @click="showStackTrace(item.stackTrace)">
                        {{ truncateAndClearText(item.stackTrace) }}
                      </div>
                      <div v-else>
                        {{ item.stackTrace }}
                      </div>
                    </td>
                  </template>
                  <template #scheduling="{ item }">
                    <td>{{ getScheduling(item) }}</td>
                  </template>
                  <template #scheduledStartDate="{ item }">
                    <td>{{ dateFormate(item.scheduledStartDate) }}</td>
                  </template>
                  <template #scheduledEndDate="{ item }">
                    <td>{{ dateFormate(item.scheduledEndDate) }}</td>
                  </template>
                  <template #constraints="{ item }">
                    <td>{{ getConstraints(item) }}</td>
                  </template>
                  <template #tags="{ item }">
                    <td>{{ getTags(item) }}</td>
                  </template>
                  <template #products="{ item }">
                    <td>{{ getProducts(item) }}</td>
                  </template>
                  <template #rewardType="{ item }">
                    <td>{{ item.rewardType.key }}</td>
                  </template>
                  <template #hostingOptions="{ item }">
                    <td>{{ getHostingOptions(item) }}</td>
                  </template>
                  <template #source="{ item }">
                    <td>{{ truncateText(item.source) }}</td>
                  </template>
                  <template #instantWinType="{ item }">
                    <td>{{ setInstantWinTypeName(item) }}</td>
                  </template>
                  <template #system-filter>
                    <div></div>
                  </template>
                  <template #unitOfMeasure="{ item }">
                    <td>{{ getUnitsOfMeasureTypeName(item.unitOfMeasure) }}</td>
                  </template>
                  <template #headers="{ item }" v-if="model === 'webhooks'">
                    <td>{{ getHeaders(item.headers) }}</td>
                  </template>
                  <template #tiles="{ item }">
                    <td>{{ getTiles(item.tiles) }}</td>
                  </template>
                  <template #select-filter>
                    <div></div>
                  </template>
                  <template #select="{ item }">
                    <td
                      :data-id="item.id"
                      :class="{ 'column-shadow-start': isTableScrollbar }"
                      class="text-center nth-select select-td fixed-column-start"
                    >
                      <ClCheckbox
                        :valueProp="isCollaborator ? item.email : item.id"
                        :checkedProp="selectedRecords.indexOf(item.id) > -1"
                        @handler="checkRecords"
                      />
                    </td>
                  </template>
                  <template #actions-header>
                    <div :class="{ 'reduction-row-actions': isTableScrollbar }" v-theme-header-actions>Actions</div>
                  </template>
                  <template #actions-filter>
                    <div></div>
                  </template>
                  <!--        ACTIONS        -->
                  <template #actions="{ item }">
                    <ClTableActionsDropdown
                      @choice="dropDownChoice"
                      :itemProp="item"
                      :actionsProp="getDropDownActions(item)"
                      :isTableScrollbarProp="isTableScrollbar"
                      :key="item.id"
                    />
                  </template>
                </CDataTable>
                <TableFooter
                  :page="page"
                  @updatePagenation="paginationChange"
                  :pages="pages"
                  :total="totalRecords"
                  :itemsPerPage="itemsPerPage"
                  :disabled="!ready"
                  @updateItemPerPage="itemsPerPageSelect"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <!--  CREATE OR LOAD  -->
      <CreateOrLoad
        :config="createOrLoadConfig"
        @click="createOrLoadClicked"
        v-else
      />
      <!-- MODAL   -->
      <Modal
        :modalShow="isShowModal"
        :messageGeneral="messageGeneral"
        :title="modalTitle"
        @doFunction="deleteSelectedField"
        v-on:toggle-modal="closeModal('delete')"
        :isSuccessButton="isModalSuccessButton"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { pageConfig } from '@/config';
import { dateFormate } from '@/utils/dateFormate';
import TableFooter from '@/components/table/Footer';
import TableHeader from '@/components/table/Header';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import Modal from '@/shared/UI/Modal';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CreateOrLoad from '@/shared/components/CreateOrLoad';
import TableLoader from '@/components/table/Loader';
import { checkNullItem } from '@/helpers/checkNullItem';
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown';
import TableResizeMixin from '@/shared/mixins/TableResizeMixin';
import { cloneDeep, delay } from 'lodash';
import PreviewSpiner from '@/shared/UI/Spiner.vue';
import { spaces } from '@/store/modules/spaces/getters';

export default {
  name: 'CIDataTable',
  components: {
    PreviewSpiner,
    TableFooter,
    TableHeader,
    ClTableActionsDropdown,
    Modal,
    ClCheckbox,
    CreateOrLoad,
    TableLoader,
  },
  props: {
    itemProp: Object,
    model: String,
    vuex: Object,
    settings: Object,
    route: Object,
    skipSort: Array,
    displayFields: Array,
    isCreateButtonVisible: {
      type: Boolean,
      default: true
    },
    isCogFieldsButtonVisible: {
      type: Boolean,
      default: true,
    },
    isFilterButtonVisible: {
      type: Boolean,
      default: true,
    },
    isShowCreateOrLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isReady: true,
      tableData: [],
      createOrLoadConfig: {
        title: this.settings.page.welcome.title,
        createTitle: this.settings.page.welcome.createTitle,
        path: this.route.create,
      },
      details: [],
      collapseDuration: 0,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      hasAtLeastOne: true,
      ready: true,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      multiFields: [],
      must: [],
      columnFilter: false,
      status: 'All',
      defaultStatus: 'All',
      statuses: [],
      isShowModal: false,
      messageGeneral: this.settings.page.deleteMessage,
      modalTitle: this.settings.page.deleteTitle,
      isModalSuccessButton: true,
      selectedId: null,
      selectedRecords: [],
      toggleFlag: false,
      activeToggleId: null,
      isTableScrollbar: false,
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editRecord',
          disabled: false,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: false,
        },
      ],
      activeCompetitionActions: [],
      readyCompetitionActions: [],
      finishedCompetitionActions: [],
      finalisedCompetitionActions: [],
      stoppedConnectionActions: [],
      startedConnectionActions: [],
      unknownConnectionActions: [],
      failedConnectionActions: [],
      messageActions: [],
      isCollaborator: false,
      columnFilterName: null,
      isLogEvents: false,
      unitOfMeasureTypeMap: {},
      renderKey: 0
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters('actionTypes', {
      actionTypesSuccess: 'success',
      actionTypesMessage: 'message',
      actionTypesLoading: 'loading',
      actionTypesData: 'actionTypes',
      optionsTypes: 'actionTypes',
    }),
    ...mapGetters('spaces', ['spaces']),
    ...mapGetters('currentPage', ['currentPageNumber', 'itemsPerPageData', 'tableModel', 'currentStatus']),
    getUnitsOfMeasureTypeName() {
      return (id) => {
        return this.unitOfMeasureTypeMap[id];
      };
    },
    theme() {
      return this.$store.getters[this.vuex.getter.theme];
    },
    fields() {
      return this.$store.getters[this.vuex.getter.fields];
    },
    tableFields() {
      let fields = this.$store.getters[this.vuex.getter.fields];
      let sortableFields = this.$store.getters[this.vuex.getter.sortableFields];
      let tableFields = [];
      fields.filter(f => f !== 'competitionType').forEach(field => {
        if (this.isCollaborator) {
          if (field !== 'id' && field !== 'availableRoles')
            tableFields.push({
              key: field,
              sorter: sortableFields.includes(field)
            });
        } else if (this.model === 'fileRepositories' && this.displayFields.length) {
          if (this.displayFields.includes(field)) {
            tableFields.push({
              key: field,
              sorter: sortableFields.includes(field)
            });
          }
        } else {
          tableFields.push({
            key: field,
            sorter: sortableFields.includes(field)
          });
        }
      });

      if (this.model === 'logEvents') {
        tableFields = tableFields.filter(field => field.key !== 'select' && field.key !== 'actions');
      }

      return tableFields;
    },
    pages() {
      if (this.model === 'collaborators') {
        return 1;
      }
      return this.$store.getters[this.vuex.getter.pages];
    },
    loading() {
      return this.$store.getters[this.vuex.getter.loading];
    },
    resultCount() {
      return this.$store.getters[this.vuex.getter.resultCount];
    },
    totalRecords() {
      return this.$store.getters[this.vuex.getter.totalRecords];
    },
    originalFields() {
      return this.$store.getters[this.vuex.getter.originalFields];
    },
    searchableAllFields() {
      return this.$store.getters[this.vuex.getter.searchableAllFields];
    },
    success() {
      return this.$store.getters[this.vuex.getter.success];
    },
    message() {
      return this.$store.getters[this.vuex.getter.message];
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc',
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      },
    },
    isDefaultTheme() {
      return this.theme === 'default';
    },
  },
  beforeMount() {
    if (this.tableModel === this.model) {
      this.page = this.currentPageNumber;
    }

    this.setAdditionalActions();
    this.setActiveCompetitionActions();
    this.setReadyCompetitionActions();
    this.setFinishedCompetitionActions();
    this.setFinalisedCompetitionActions();
    this.setStoppedConnectionActions();
    this.setStartedConnectionActions();
    this.setUnknownConnectionActions();
    this.setFailedConnectionActions();
    this.setMessageActions();
    if (this.model === 'logEvents') {
      this.isLogEvents = true;
    }
    if (this.model === 'awards') {
      this.setAwardsActions();
    }
  },
  mixins: [TableResizeMixin('dataTableRef')],
  methods: {
    ...mapActions('actionTypes', ['handleGetActionTypesByQuery']),
    ...mapActions('unitsOfMeasure', ['handleGetUnitsOfMeasureByQuery']),
    ...mapActions('files', ['handleGetFileObjectsByQuery', 'handleDeleteFileObjects']),
    ...mapActions('fileRepositories', ['handleGetFileRepositoriesByQuery']),
    ...mapActions('currentPage', ['setCurrentPageNumber', 'setItemsPerPage', 'setTableModel', 'setCurrentStatus']),
    async initialize() {
      if (this.tableModel === this.model) {
        await this.itemsPerPageSelect(this.itemsPerPageData);
      } else {
        this.setTableModel(this.model);
        this.setItemsPerPage(this.itemsPerPage);
        this.setCurrentPageNumber(this.page);
        this.setCurrentStatus('');
      }

      if (this.model === 'collaborators') {
        this.itemsPerPage = 1000;
      }
      routerBreadcrumbs(this.$router.currentRoute);
      if (this.settings.statuses && this.settings.statuses.length) {
        this.statuses = this.settings.statuses;
        this.hasAtLeastOne = true;
      } else {
        const data = await this.queryDB();
        this.hasAtLeastOne = data && data.length > 0;
        if (!this.isShowCreateOrLoad) {
          this.hasAtLeastOne = true;
        }
      }

      this.defaultStatus = this.settings.defaultStatus;

      if (this.$route.params && this.$route.params.status) {
        this.defaultStatus = this.$route.params.status;
      }

      this.ready = true;
      await this.$store.dispatch(this.vuex.actions.fields);

      await this.updateFields();

      this.checkIsCollaborator();
      this.renderKey++;
    },
    getDropDownActions(item) {
      if (typeof item === 'object' && ({}).hasOwnProperty.call(item, 'competitionType')) {
        switch (item.status) {
          case 'Ready':
            return this.readyCompetitionActions;
          case 'Active':
            return this.activeCompetitionActions;
          case 'Finished':
            return this.finishedCompetitionActions;
          case 'Finalised':
            return this.finalisedCompetitionActions;
          default:
            return this.dropDownActions;
        }
      } else if (typeof item === 'object' && ({}).hasOwnProperty.call(item, 'connectionType')) {
        if (item.lastKnownStatus === 'Unknown') {
          return this.unknownConnectionActions;
        } else if (item.lastKnownStatus === 'Stopped') {
          return this.stoppedConnectionActions;
        } else if (item.lastKnownStatus === 'Failed') {
          return this.failedConnectionActions;
        } else {
          return this.startedConnectionActions;
        }
      } else if (typeof item === 'object' && ({}).hasOwnProperty.call(item, 'messageType')) {
        return this.messageActions;
      } else {
        return this.dropDownActions;
      }
    },
    setAwardsActions() {
      this.dropDownActions = [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: false,
        },
      ];
    },
    setAdditionalActions() {
      if (this.vuex.actions.clone) {
        this.dropDownActions.splice(2, 0, {
          title: this.$t('buttons.clone'),
          handler: 'cloneRecord',
          disabled: false,
        });
      }

      if (this.vuex.actions.updateStatus) {
        this.dropDownActions.splice(2, 0, {
          title: this.$t('buttons.sendStatus'),
          handler: 'setSendStatus',
          disabled: true,
        });
      }
    },
    setMessageActions() {
      this.messageActions = [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: false,
        },
      ];
    },
    setActiveCompetitionActions() {
      this.activeCompetitionActions = cloneDeep(this.dropDownActions);
      this.activeCompetitionActions.push(...[
        {
          title: 'Finish',
          handler: 'setFinishedStatus',
        },
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ]);
    },
    setReadyCompetitionActions() {
      this.readyCompetitionActions = cloneDeep(this.dropDownActions);
      this.readyCompetitionActions.push(...[
        {
          title: 'Start',
          handler: 'setActiveStatus',
        },
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ]);
    },
    setFinishedCompetitionActions() {
      this.finishedCompetitionActions = cloneDeep(this.dropDownActions);
      this.finishedCompetitionActions.push(...[
        {
          title: 'Finalise',
          handler: 'setFinalisedStatus',
        },
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ]);
    },
    setFinalisedCompetitionActions() {
      this.finalisedCompetitionActions = cloneDeep(this.dropDownActions);
      this.finalisedCompetitionActions.push(...[
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ]);
    },
    setUnknownConnectionActions() {
      this.unknownConnectionActions = cloneDeep(this.dropDownActions);
      this.unknownConnectionActions.push(...[
        {
          title: 'Start',
          handler: 'setStartedStatus',
          disabled: true,
          stroked: true,
        }
      ]);
    },
    setStoppedConnectionActions() {
      this.stoppedConnectionActions = cloneDeep(this.dropDownActions);
      this.stoppedConnectionActions.push(...[
        {
          title: 'Start',
          handler: 'setStartedStatus',
        }
      ]);
    },
    setStartedConnectionActions() {
      this.startedConnectionActions = cloneDeep(this.dropDownActions);
      this.startedConnectionActions.push(...[
        {
          title: 'Stop',
          handler: 'setStoppedStatus',
        }
      ]);
    },
    setFailedConnectionActions() {
      this.failedConnectionActions = cloneDeep(this.dropDownActions);
      this.failedConnectionActions.push(...[
        {
          title: 'Start',
          handler: 'setStartedStatus',
        }
      ]);
    },
    getActionsAdjustmentFactorsFields(ActionTypes) {
      let actionTypeAdjustmentFactorsFields = [];
      if (ActionTypes === []) return false;
      if (ActionTypes.length && Array.isArray(ActionTypes)) {
        ActionTypes.forEach(item => {
          let name = this.optionsTypes.find(option => option.id === item.actionTypeId)?.name;
          const handles = [name, item.adjustmentFactor].join(':  ');
          actionTypeAdjustmentFactorsFields.push(handles);
          actionTypeAdjustmentFactorsFields.join(' ');
        });
      } else {
        return ActionTypes;
      }

      return actionTypeAdjustmentFactorsFields;
    },
    getMetaData(metaData) {
      let meta = '';
      const maxlength = 20;
      if (metaData && Object.values(metaData).length > 0) {
        Object.values(metaData).forEach(element => {
          meta += element + '\n';
        });
      }
      return meta.length > maxlength
        ? meta.substring(0, maxlength - 3) + '...'
        : meta.substring(0, maxlength);
    },

    async queryDB() {
      const data = await this.$store.dispatch(this.vuex.actions.query, {
        queryRequest:
          {
            must: this.must,
            multiFields: this.multiFields,
            sortBy: this.sortBy,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
      });

      this.tableData = this.$store.getters[this.vuex.getter.data];

      if (this.model === 'products') {
        const actionTypeIds = [];
        data.forEach(item => {
          if (item.actionTypeAdjustmentFactors && item.actionTypeAdjustmentFactors.length) {
            item.actionTypeAdjustmentFactors.forEach(action => actionTypeIds.push(action.actionTypeId));
          }
        });

        let uniqueIds = [...new Set(actionTypeIds)];

        const queryRequest = {
          must: [
            {
              queryField: 'id',
              queryValues: uniqueIds
            }
          ]
        };

        await this.handleGetActionTypesByQuery({ queryRequest });
      }

      if (this.model === 'rewardTypes' || this.model === 'actionTypes') {
        const uomIds = data.map(item => item.unitOfMeasure);

        const queryRequest = {
          must: [
            {
              queryField: 'id',
              queryValues: uomIds
            }
          ]
        };

        const unitsOfMeasure = await this.handleGetUnitsOfMeasureByQuery({ queryRequest });

        const unitsOfMeasureTypes = ['Other', 'Currency', 'Mass', 'Time', 'Temperature', 'ElectricCurrent', 'AmountOfSubstance', 'LuminousIntensity', 'Distance'];

        this.tableData.forEach(item => {
          if (!unitsOfMeasureTypes.includes(item.unitOfMeasure)) {
            const unit = unitsOfMeasure.find(unit => unit.id === item.unitOfMeasure);
            this.unitOfMeasureTypeMap[item.unitOfMeasure] = unit.unitOfMeasureType;
          } else {
            this.unitOfMeasureTypeMap[item.unitOfMeasure] = item.unitOfMeasure;
          }
        });
      }

      if (this.tableData.length) {
        this.tableData = checkNullItem(this.tableData);
      }

      await this.updateFields();

      this.isReady = true;
      return data;
    },
    async statusQueryDb() {
      delay(async () => {
        this.tableData = cloneDeep([]);
        if (this.status !== 'All') {
          // this.multiFields = [];
          await this.$store.dispatch(this.vuex.actions.query, {
            queryRequest:
              {
                must: [
                  {
                    queryField: 'status',
                    queryValues: [this.status]
                  }
                ],
                multiFields: this.multiFields,
                sortBy: this.sortBy,
                limit: this.itemsPerPage,
                skip: (this.page - 1) * this.itemsPerPage
              }
          });
          this.tableData = this.$store.getters[this.vuex.getter.data];
        } else {
          await this.queryDB();
        }
        this.isReady = true;
      }, 1000);
    },
    async navQueryDb() {
      if (this.status && this.status.toLowerCase() !== 'all') {
        await this.$store.dispatch(this.vuex.actions.query, {
          queryRequest:
            {
              must: [
                {
                  queryField: 'status',
                  queryValues: [this.status]
                }
              ],
              sortBy: this.sortBy,
              limit: this.itemsPerPage,
              skip: (this.page - 1) * this.itemsPerPage
            }
        });
        this.tableData = this.$store.getters[this.vuex.getter.data];
      } else {
        await this.queryDB();
      }
      this.isReady = true;
    },
    handleToggle(id) {
      this.activeToggleId = id;
    },
    async itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;

      if (this.itemsPerPageData && this.itemsPerPageData !== val) {
        this.setItemsPerPage(val);
      }

      await this.navQueryDb();
    },
    async paginationChange(val) {
      if (this.currentPageNumber && this.currentPageNumber !== val) {
        this.setCurrentPageNumber(val);
      }

      this.page = val;
      await this.navQueryDb();
      await this.updateFields();
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    getSpaceName(id) {
      let name = id;

      if (this.model === 'members') {
        const space = this.spaces.find((s) => s.id === id);
        if (space) {
          name = space.spaceName;
        }
      }

      return name;
    },
    async updateFields(val) {
      await this.$store.dispatch(this.vuex.actions.fields, val);
    },
    createOrLoadClicked() {
      this.createNewRecord();
    },
    createNewRecord() {
      if (this.route.create) {
        this.$router.push({
          name: this.route.create,
        });
      }
      this.$emit('createRecord');
    },
    editRecord(val) {
      if (this.route.edit) {
        if (val.email) {
          this.$router.push({
            name: this.route.edit,
            params: {
              id: val.email,
            },
          });
        } else {
          this.$router.push({
            name: this.route.edit,
            params: {
              id: val.id,
              name: val.name,
            },
          });
        }
      }
      this.$emit('editRecord', val);
    },
    cloneRecord(val) {
      this.$router.push({
        name: this.route.create,
        query: { cloneId: val.id },
      });
    },
    async setSendStatus(val) {
      await this.$store.dispatch(this.vuex.actions.updateStatus, {
        updateMessageStatusRequestArray: [{ id: val.id, status: 'Sent' }]
      });
    },
    async setActiveStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateCompetitionStatusRequestArray: [{ id: val.id, status: 'Active' }]
        }
      );

      await this.statusQueryDb();
    },
    async setFinishedStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateCompetitionStatusRequestArray: [{ id: val.id, status: 'Finished' }]
        }
      );

      await this.statusQueryDb();
    },
    async setCancelStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateCompetitionStatusRequestArray: [{ id: val.id, status: 'Cancelled' }]
        }
      );

      await this.statusQueryDb();
    },
    async setFinalisedStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateCompetitionStatusRequestArray: [{ id: val.id, status: 'Finalised' }]
        }
      );

      await this.statusQueryDb();
    },
    async setStartedStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateConnectionStateRequestArray: [{ id: val.id, status: 'Start' }]
        }
      );

      await this.statusQueryDb();
    },
    async setStoppedStatus(val) {
      this.isReady = false;
      await this.$store.dispatch(
        this.vuex.actions.status,
        {
          updateConnectionStateRequestArray: [{ id: val.id, status: 'Stop' }]
        }
      );

      await this.statusQueryDb();
    },
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.multiFields = [];
        this.formatFilter();
      }
    },
    async checkStatus(val) {
      if (this.tableModel === this.model && this.renderKey === 0 && this.currentStatus) {
        this.status = this.currentStatus;
        this.defaultStatus = this.currentStatus;
        this.page = this.currentPageNumber;
      } else {
        this.setCurrentStatus(val);
        this.status = val;
        this.defaultStatus = val;
        this.page = 1;
      }

      await this.navQueryDb();
      await this.updateFields();
    },
    truncateText(text) {
      let sliced = text.slice(0, 100);
      if (sliced.length < text.length) {
        sliced += '...';
      }

      return sliced;
    },
    truncateAndClearText(text) {
      text = text.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim();
      let sliced = text.slice(0, 100);
      if (sliced.length < text.length) {
        sliced += '...';
      }

      return sliced;
    },
    async formatFilter() {
      // filter toggled and closed this func will trigger
      this.$refs.dataTableRef.clean();
      this.page = 1;
      this.query = {};
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      await this.queryDB();
    },
    async handleSortBy(e) {
      // Single field level sorting
      if (this.skipSort && this.skipSort.includes(e.column)) {
        return;
      }
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      await this.statusQueryDb();
    },
    async handleColumnFilter(e) {
      const columnFilterName = Object.keys(e)[Object.keys(e).length - 1];
      this.multiFields = [];
      if (this.status && this.status.toLowerCase() !== 'all') {
        this.must = [
          {
            queryField: 'status',
            queryValues: [this.status]
          }
        ];
      }
      let str = '';
      Object.keys(e).forEach((x) => {
        if (e[x] !== ' ') {
          str = e[x].trim();
          if (str.length > 0) {
            this.sortBy = [
              {
                queryField: '_score',
                order: 'Desc',
              },
            ];

            if (x === 'unitOfMeasure') x = 'unitOfMeasureType';

            this.multiFields.push({
              queryFields: [x],
              queryValue: str,
            });
          } else {
            return false;
          }
        }
      });
      await this.queryDB();

      this.$refs.dataTableRef.$el
        .querySelector('[aria-label="column name: \'' + columnFilterName + '\' filter input"]')
        .focus();
    },
    async searchData(val) {
      this.columnFilter = false;
      if (this.status && this.status.toLowerCase() !== 'all') {
        this.must = [
          {
            queryField: 'status',
            queryValues: [this.status]
          }
        ];
      }
      this.sortBy = [
        {
          queryField: '_score',
          order: 'Desc',
        },
      ];
      this.multiFields = [];
      if (val.trim() !== '') {
        this.multiFields.push({
          queryFields: this.searchableAllFields,
          queryValue: val.trim(),
        });
      }

      if (this.isLogEvents && val.trim() !== '') {
        this.multiFields = [
          {
            queryFields: ['id'],
            queryValue: val.trim(),
          }
        ];
      }

      await this.queryDB();
    },
    async deleteSelectedField() {
      this.isShowModal = false;

      if (this.model === 'competitions') {
        let payload = [];
        this.selectedId.forEach(id => {
          payload.push({ id: id, status: 'Deleted' });
        });
        await this.$store.dispatch(this.vuex.actions.status, { updateCompetitionStatusRequestArray: payload });
      } else {
        const payload = this.isCollaborator ? { emailsArray: [this.selectedId] } : { idArray: [this.selectedId] };
        await this.$store.dispatch(this.vuex.actions.delete, payload);
      }

      if (this.model === 'instantWins') {
        const repository = await this.handleGetFileRepositoriesByQuery({
          queryRequest: {
            must: [{
              queryField: 'name',
              queryValues: ['system-resources'],
            }]
          }
        });


        const sortBy = [{
          queryField: 'name',
          order: 'Asc',
        }];

        const folderName = '/instant-wins';

        const query = {
          queryRequest: {
            must: [
              {
                queryField: 'repositoryId',
                queryValues: [repository[0].id],
              },
              {
                queryField: 'parentFolderPath',
                queryValues: [folderName],
              },
              {
                queryField: 'name',
                queryValues: [this.selectedId[0]],
              },
            ],
            sortBy: sortBy,
            limit: this.itemsPerPage,
            skip: 0
          }
        };
        const fileResponse = await this.handleGetFileObjectsByQuery(query);
        const file = fileResponse[0];
        if (file) {
          await this.handleDeleteFileObjects({ idArray: [file.id] });
        }
      }


      this.selectedRecords = [];
      this.toggleFlag = false;
      await this.statusQueryDb();
    },
    closeModal() {
      this.isShowModal = false;
    },
    showStackTrace(stackTrace) {
      this.messageGeneral = stackTrace;
      this.modalTitle = 'Stack Trace';
      this.isModalSuccessButton = false;
      this.isShowModal = true;
    },
    showPreview(item) {
      if (this.route.preview) {
        if (item.email) {
          this.$router.push({
            name: this.route.preview,
            params: {
              id: item.email,
            },
          });
        } else {
          this.$router.push({
            name: this.route.preview,
            params: {
              id: item.id,
              name: item.name,
            },
          });
        }
      }
      this.$emit('previewRecord', item);
    },
    clearFilter() {
      this.$store.dispatch(this.vuex.actions.reset);
    },
    deleteData() {
      this.messageGeneral =
        'You are about to delete ' +
        this.selectedRecords.length + ' ' + this.settings.page.pageTitle.toLowerCase() +
        ', Are you sure?';
      this.isShowModal = true;
      this.selectedId = cloneDeep(this.selectedRecords);
    },
    async cancelAction() {
      if (this.selectedRecords && this.selectedRecords.length) {
        const requestArray = this.selectedRecords.map(r => {
          return { id: r, status: 'Cancelled' };
        });

        await this.$store.dispatch(
          this.vuex.actions.status,
          {
            updateCompetitionStatusRequestArray: requestArray
          }
        );

        await this.statusQueryDb();
      }
    },
    checkRecords({ event }) {
      const index = this.selectedRecords.indexOf(event.target.value);
      if (index > -1) {
        this.selectedRecords.splice(index, 1);
      } else {
        this.selectedRecords.push(event.target.value);
      }
    },
    selectAll() {
      const getFields = cloneDeep(this.$store.getters[this.vuex.getter.data]);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag)
        getFields.forEach((item) => this.selectedRecords.push(item.id));
      else this.selectedRecords = [];
    },
    handleDeleteModal(item) {
      this.isShowModal = true;
      this.selectedId = !this.isCollaborator ? [item.id] : [item.email];
      this.messageGeneral = this.settings.page.deleteMessage;
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item);
      }
    },
    getCustomFields(items) {
      if (items && items.length > 0 && items !== '-') {
        return items;
      } else return '-';
    },
    getScheduling(item) {
      if (item && item.scheduling) {
        let startDate = dateFormate(item.scheduling.startDate).split(',')[0];
        let endDate = dateFormate(item.scheduling.endDate).split(',')[0];
        return `${ startDate } - ${ endDate }`;
      }
    },
    getConstraints(item) {
      if (Array.isArray(item.constraints)) {
        return item.constraints.length ? item.constraints.join(', ') : '-';
      }
      return item.constraints;
    },
    getTags(item) {
      if (Array.isArray(item.tags)) {
        return item.tags.length ? item.tags.join(', ') : '-';
      }
      return item.tags;
    },
    getProducts(item) {
      if (Array.isArray(item.products) && item.products.length) {
        let productsOutput = [];
        item.products.forEach(product => {
          productsOutput.push(product.name);
        });
        if (productsOutput.length > 3) {
          productsOutput = productsOutput.slice(0, 3);
          productsOutput = productsOutput.join(', ') + ' ...';
        } else {
          productsOutput = productsOutput.join(', ');
        }

        return productsOutput;
      }

      return '-';
    },
    getHostingOptions(item) {
      let result = '';
      for (const [key, value] of Object.entries(item.hostingOptions)) {
        result = result + `${ key }: ${ value }; `;
      }

      return result;
    },
    getHeaders(items) {
      // Specific method for webhooks only
      let header = '';
      let headerArr = [];
      const maxlength = 30;
      if (items && items.length > 0 && items !== '-') {
        items.forEach((element) => {
          let headerStr = element.key + ': ' + element.value;
          headerArr.push(headerStr);
        });
        if (headerArr.length) {
          header = headerArr.join(', ');
        }
      }

      if (!header.length) return '-';

      return header.length > maxlength
        ? header.substring(0, maxlength - 3) + '...'
        : header.substring(0, maxlength);
    },
    getTiles(tiles) {
      let res = '';
      if (tiles && tiles.length) {
        const resArray = tiles.map(t => this.truncateAndClearText(t.text));
        res = resArray.join(', ');
      }
      return res;
    },
    handleCopy(id) {
      navigator.clipboard.writeText(id);
    },
    checkIsCollaborator() {
      this.$router.currentRoute.name === 'Collaborators'
        ? this.isCollaborator = true
        : this.isCollaborator = false;
    },
    async reload() {
      if (this.model === 'logEvents') {
        this.multiFields = [];
      }
      if (this.settings.statuses && this.settings.statuses.length) {
        await this.navQueryDb();
      }
      await this.initialize();
    },
    uploadFromCSV(file) {
      this.$emit('uploadFromCSV', file);
    },
    setInstantWinTypeName(item) {
      return item.instantWinType === 1 ? 'Spinner' : 'Scratchcard';
    }
  },
};
</script>

<style lang="scss">
#dataTableId {
  .nth-select {
    width: 30px;
    min-width: 30px
  }

  .nth-id {
    width: 10%;
    min-width: 230px
  }

  .nth-space-name {
    width: 5%;
  }

  .nth-created {
    width: 100px;
  }

  .nth-name {
    width: 10%;
  }

  .nth-adjustment-factor {
    width: 4%;
  }

  .nth-action-type {
    width: 12%;
    min-width: 150px;
  }

  .text-left {
    text-align: left;
  }

  .border-b {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;

    &:before {
      content: "";
      position: absolute;
      width: 80%;
      height: 1px;
      color: #000;
      background: rgb(79, 79, 79);
      background: linear-gradient(90deg, rgba(198, 198, 198, 1) 0%, rgba(255, 255, 255, 0) 70%);
      bottom: 0;
    }
  }

  .copy-ceil {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .zq-card-row--copy-icon {
    margin-left: 0.5rem;
    cursor: copy;
  }
}

.zq--modal {
  .modal-content {
    .modal-body {
      overflow-wrap: anywhere;
    }
  }
}
</style>
